/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Currentdb } from '../models/currentdb';
@Injectable({
  providedIn: 'root',
})
class CurrentdbService extends __BaseService {
  static readonly getCurrentdbPath = '/currentdb';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CurrentdbService.GetCurrentdbParams` containing the following parameters:
   *
   * - `select`: Filtering Columns
   *
   * - `order`: Ordering
   *
   * - `offset`: Limiting and Pagination
   *
   * - `num`:
   *
   * - `name`:
   *
   * - `longitude`:
   *
   * - `limit`: Limiting and Pagination
   *
   * - `latitude`:
   *
   * - `current_database`:
   *
   * - `code`:
   *
   * - `cctv_timeout`:
   *
   * - `brainbox_server`:
   *
   * - `alert_server`:
   *
   * - `Range-Unit`: Limiting and Pagination
   *
   * - `Range`: Limiting and Pagination
   *
   * - `Prefer`: Preference
   *
   * @return OK
   */
  getCurrentdbResponse(params: CurrentdbService.GetCurrentdbParams): __Observable<__StrictHttpResponse<Array<Currentdb>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.select != null) __params = __params.set('select', params.select.toString());
    if (params.order != null) __params = __params.set('order', params.order.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.num != null) __params = __params.set('num', params.num.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    if (params.currentDatabase != null) __params = __params.set('current_database', params.currentDatabase.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    if (params.cctvTimeout != null) __params = __params.set('cctv_timeout', params.cctvTimeout.toString());
    if (params.brainboxServer != null) __params = __params.set('brainbox_server', params.brainboxServer.toString());
    if (params.alertServer != null) __params = __params.set('alert_server', params.alertServer.toString());
    if (params.RangeUnit != null) __headers = __headers.set('Range-Unit', params.RangeUnit.toString());
    if (params.Range != null) __headers = __headers.set('Range', params.Range.toString());
    if (params.Prefer != null) __headers = __headers.set('Prefer', params.Prefer.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/currentdb`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Currentdb>>;
      })
    );
  }
  /**
   * @param params The `CurrentdbService.GetCurrentdbParams` containing the following parameters:
   *
   * - `select`: Filtering Columns
   *
   * - `order`: Ordering
   *
   * - `offset`: Limiting and Pagination
   *
   * - `num`:
   *
   * - `name`:
   *
   * - `longitude`:
   *
   * - `limit`: Limiting and Pagination
   *
   * - `latitude`:
   *
   * - `current_database`:
   *
   * - `code`:
   *
   * - `cctv_timeout`:
   *
   * - `brainbox_server`:
   *
   * - `alert_server`:
   *
   * - `Range-Unit`: Limiting and Pagination
   *
   * - `Range`: Limiting and Pagination
   *
   * - `Prefer`: Preference
   *
   * @return OK
   */
  getCurrentdb(params: CurrentdbService.GetCurrentdbParams): __Observable<Array<Currentdb>> {
    return this.getCurrentdbResponse(params).pipe(
      __map(_r => _r.body as Array<Currentdb>)
    );
  }
}

module CurrentdbService {

  /**
   * Parameters for getCurrentdb
   */
  export interface GetCurrentdbParams {

    /**
     * Filtering Columns
     */
    select?: string;

    /**
     * Ordering
     */
    order?: string;

    /**
     * Limiting and Pagination
     */
    offset?: string;
    num?: string;
    name?: string;
    longitude?: string;

    /**
     * Limiting and Pagination
     */
    limit?: string;
    latitude?: string;
    currentDatabase?: string;
    code?: string;
    cctvTimeout?: string;
    brainboxServer?: string;
    alertServer?: string;

    /**
     * Limiting and Pagination
     */
    RangeUnit?: string;

    /**
     * Limiting and Pagination
     */
    Range?: string;

    /**
     * Preference
     */
    Prefer?: 'count=none' | 'count=exact';
  }
}

export { CurrentdbService }
