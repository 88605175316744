import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GlobalsService {
  globals = {
    projectCode: "NOW",
    projectName: "NowWireless",
    projectNum: 0,
    latitude: 51.2997683,
    longitude: -0.0729089,
    cctv_timeout: 0,
  } as any;

  constructor() {}

  set(key, value) {
    this.globals[key] = value;
  }
  get(key) {
    return this.globals[key];
  }
}
