import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MatSliderModule } from "@angular/material/slider";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { DefaultLayoutComponent } from "./containers";

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from "@coreui/angular";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { GlobalsService } from "./global.service";
import { SidebarNavEdittableComponent } from "./sidebar-nav-edittable/sidebar-nav-edittable.component";
import { SidebarNavEdittableService } from "./sidebar-nav-edittable/sidebar-nav-edittable.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
//import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { SAVER, getSaver } from "./senseview/video/saver.provider";

@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS, SidebarNavEdittableComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    HttpClientModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    //  NoopAnimationsModule,
    MatSliderModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    SidebarNavEdittableService,
    { provide: SAVER, useFactory: getSaver },
    GlobalsService,
  ],
  bootstrap: [AppComponent],
  exports: [SidebarNavEdittableComponent],
})
export class AppModule {}
