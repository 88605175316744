import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DefaultLayoutComponent } from "./containers";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full"
  },

  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home"
    },
    children: [
      {
        path: "senseview",
        loadChildren: () => import('./senseview/senseview.module').then(m => m.SenseviewModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
