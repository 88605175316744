import { Injectable, Output, EventEmitter } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SidebarNavEdittableService {
  @Output() updateMenu: EventEmitter<string> = new EventEmitter();

  changeMenu(inputString: string): void {
    this.updateMenu.emit(inputString);
  }
}
