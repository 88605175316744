<app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{
    src: 'assets/img/brand/nowwireless.png',
    width: 119.2,
    height: 25,
    alt: 'Now Wireless'
  }"
  [navbarBrandMinimized]="{
    src: 'assets/img/brand/nowarrows.png',
    width: 30,
    height: 30,
    alt: 'Now Wireless'
  }"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false"
>
  <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item dropdown px-3" dropdown placement="bottom right">
      <a
        class="nav-link dropdown-toggle"
        data-toggle="dropdown"
        href="#"
        dropdownToggle
        role="button"
        (click)="(false)"
        >Generate Graphs</a
      >
      <div class="dropdown-menu" *dropdownMenu>
        <a class="dropdown-item" routerLink="/senseview/graph/routes"
          >Journey</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/anpr-journey"
          >ANPR Journey</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/eroutes"
          >Extended Journey</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/journeycount"
          >Journey Count</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/pollution"
          >Pollution (NO2)</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/pollution_no"
          >Pollution (NO)</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/particulate"
          >Particulate</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/videoai"
          >Vehicle Classification</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/allvideo"
          >Vehicle Classification (All Zones)</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/videospeed"
          >Vehicle Speeds</a
        >
        <a class="dropdown-item" routerLink="/senseview/graph/speedmulti"
          >Vehicle Speeds (Multiple Zones)</a
        >
        <!--<a class="dropdown-item" routerLink="/senseview/graph/allspeed"
          >Vehicle Speeds (All Zones)</a
        >-->
      </div>
    </li>
    <li class="nav-item dropdown px-3" dropdown placement="bottom right">
      <a
        class="nav-link dropdown-toggle"
        data-toggle="dropdown"
        href="#"
        dropdownToggle
        role="button"
        (click)="(false)"
        >Export Data</a
      >
      <div class="dropdown-menu" *dropdownMenu>
        <a class="dropdown-item" routerLink="/senseview/export/routes"
          >Journey</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/anpr-journey"
          >ANPR Journey</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/eroutes"
          >Extended Journey</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/journeycount"
          >Journey Count</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/pollution"
          >Pollution (NO2)</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/pollution_no"
          >Pollution (NO)</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/particulate"
          >Particulate</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/videoai"
          >Vehicle Classification</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/allvideo"
          >Vehicle Classification (All Zones)</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/videospeed"
          >Vehicle Speeds</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/speedmulti"
          >Vehicle Speeds (Multiple Zones)</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/anpr-class"
          >ANPR Classification</a
        >
        <a class="dropdown-item" routerLink="/senseview/export/anpr-register"
          >ANPR Registry</a
        >
        <!--<a class="dropdown-item" routerLink="/senseview/export/allspeed"
          >Vehicle Speeds (All Zones)</a
        > -->
      </div>
    </li>
  </ul>
  <ul class="nav navbar-nav ml-auto"></ul>
  <form class="float-right">
    <span style="padding-right: 10px;" *ngIf="navItems"
      >{{
        globals.get("projectName") + " (" + globals.get("projectCode") + ")  "
      }}
    </span>
  </form>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav-edittable
      class="sidebar-nav ps"
      [navItems]="navItems"
      [disabled]="sidebarMinimized"
    ></app-sidebar-nav-edittable>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb> <!-- Breadcrumb Menu --> </cui-breadcrumb>
    <div class="container-fluid"><router-outlet></router-outlet></div>
    <!-- /.container-fluid -->
  </main>
</div>
<app-footer>
  <span
    ><a href="https://nowwireless.com">Senseview</a> &copy; 2019 Now Wireless
    Ltd.</span
  >
  <!--
    <span class="ml-auto">Powered by <a href="https://www.nowwireless.com">CoreUI for Angular</a></span>
  -->
</app-footer>
