import { Component, OnInit, Input } from "@angular/core";
import { SidebarNavEdittableService } from "./sidebar-nav-edittable.service";

@Component({
  selector: "app-sidebar-nav-edittable",
  templateUrl: "./sidebar-nav-edittable.component.html",
  styleUrls: ["./sidebar-nav-edittable.component.css"]
})
export class SidebarNavEdittableComponent implements OnInit {
  public navItemsBase = [];

  @Input() navItems: Array<any>;
  @Input() disabled: boolean;
  constructor(private sbService: SidebarNavEdittableService) {}

  ngOnInit() {
    this.navItemsBase = this.navItems;
    this.sbService.updateMenu.subscribe(inString => {
      this.updateMenu(inString);
    });
  }

  updateMenu(menuItemsList: string): void {
    this.navItems = this.navItemsBase.filter(x => {
      return menuItemsList.includes(x.name) || x.title;
    });
  }
}
