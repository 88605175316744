import { Component, OnDestroy, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { GlobalsService } from "../../global.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { forkJoin } from "rxjs";
import { navItems } from "../../_nav";
import { SidebarNavEdittableService } from "../../sidebar-nav-edittable/sidebar-nav-edittable.service";
import { CurrentdbService } from "../../senseview/api/services/currentdb.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent implements OnDestroy {
  public navItemsBase = navItems;
  public navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  constructor(
    private globals: GlobalsService,
    private http: HttpClient,
    currentDBService: CurrentdbService,
    @Inject(DOCUMENT) _document?: any
  ) {
    forkJoin([
      currentDBService.getCurrentdb({}),
      this.http.get("/permissions.json"),
    ]).subscribe((xs) => {
      const [p, d] = xs;
      var permissionsJson = d;
      var currentDB = p[0].current_database.split("_")[1].toUpperCase();
      this.globals.set("projectCode", currentDB);
      this.globals.set("projectName", p[0].name);
      this.globals.set("projectNum", p[0].num);
      this.globals.set("latitude", p[0].latitude);
      this.globals.set("longitude", p[0].longitude);
      this.globals.set("brainbox_server", p[0].brainbox_server);
      this.globals.set("alert_server", p[0].alert_server);
      this.globals.set("cctv_timeout", p[0].cctv_timeout);
      var tempNavItems = [];
      this.navItemsBase.map((x) => {
        var tempNavItem = Object.assign({}, x);
        if (permissionsJson[x.name] && permissionsJson[x.name].permission) {
          tempNavItem.permission = permissionsJson[x.name].permission;
        }
        if (permissionsJson[x.name] && permissionsJson[x.name].deny) {
          tempNavItem.deny = permissionsJson[x.name].deny;
        }
        if (permissionsJson[x.name] && permissionsJson[x.name].displayMode) {
          tempNavItem.displayMode = permissionsJson[x.name].displayMode;
        }
        if (tempNavItem.children && tempNavItem.children.length > 0) {
          var tempChildren = [];
          for (var i = 0; i < tempNavItem.children.length; i++) {
            var y = Object.assign({}, tempNavItem.children[i]);
            if (
              permissionsJson[x.name] &&
              permissionsJson[x.name].children &&
              permissionsJson[x.name].children[y.name] &&
              permissionsJson[x.name].children[y.name].permission
            ) {
              y.permission =
                permissionsJson[x.name].children[y.name].permission;
            }
            if (
              permissionsJson[x.name] &&
              permissionsJson[x.name].children &&
              permissionsJson[x.name].children[y.name] &&
              permissionsJson[x.name].children[y.name].deny
            ) {
              y.deny = permissionsJson[x.name].children[y.name].deny;
            }
            if (
              permissionsJson[x.name] &&
              permissionsJson[x.name].children &&
              permissionsJson[x.name].children[y.name] &&
              permissionsJson[x.name].children[y.name].displayMode
            ) {
              y.displayMode =
                permissionsJson[x.name].children[y.name].displayMode;
            }
            var show =
              (!y.permission ||
                y.permission.toUpperCase().includes(currentDB)) &&
              (!y.deny || !y.deny.toUpperCase().includes(currentDB));
            if (show) {
              tempChildren.push(y);
            } else if (y.url.match("toBeDisabled")) {
              y.attributes = { disabled: true };
              tempChildren.push(y);
            } else if (y.displayMode == "disabled") {
              y.attributes = { disabled: true };
              tempChildren.push(y);
            }
          }
          tempNavItem.children = tempChildren;
        }
        if (
          (!tempNavItem.permission ||
            tempNavItem.permission.toUpperCase().includes(currentDB)) &&
          (!tempNavItem.deny ||
            !tempNavItem.deny.toUpperCase().includes(currentDB))
        ) {
          tempNavItems.push(tempNavItem);
        } else if (
          tempNavItem.displayMode &&
          tempNavItem.displayMode == "disabled"
        ) {
          if (tempNavItem.children) delete tempNavItem.children;
          tempNavItem.attributes = { disabled: true };
          tempNavItems.push(tempNavItem);
        }
      });
      this.navItems = tempNavItems;
    });
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains(
        "sidebar-minimized"
      );
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ["class"],
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
